/*
 * This is to improve the UX of clicking on cards in the account pages.
 * Due to not being able to contain link elements inside link elements, and that there may be a
 * linked button on some cards where e.g. there is a payment problem, this file increases
 * the click area of the cards so that the whole card is clickable and keeps the markup valid.
 */

define(['app', '$location'], function (app, $location) {

  var _card = {}

  _card._elementSelectors = {
    card: '[data-component="card"]',
    linkedAccountCreditCard_howToButton: '.linkedAccountCreditCard_howToButton',
    linkedAccountCreditCard_howToList: '.linkedAccountCreditCard_howToList'
  };

  _card._domElements = {};

  _card._init = function () {
    _card._domElements.howToButton = document.querySelector(_card._elementSelectors.linkedAccountCreditCard_howToButton);
    _card._domElements.howToList = document.querySelector(_card._elementSelectors.linkedAccountCreditCard_howToList);
    _card._bindEvents();
  };

  _card._bindEvents = function () {
    var cards = document.querySelectorAll(_card._elementSelectors.card);

    app.on('click', _card._goToCard, cards);
    app.on('click', _card._accountCreditCardButtonClick, _card._domElements.howToButton)
    //_card._domElements.howToButton.addEventListener('click', _card._accountCreditCardButtonClick);
  };

  _card._goToCard = function (event) {
    event = event || window.event;
    if (!event) {
      return;
    }
    var target = event.target || event.srcElement;
    if (!target) {
      return;
    }

    if (target.dataset.link) {
      $location.assign(target.dataset.link);
    }
  };

  // Focus on click is not triggered on Safary so this code does what sass is doing for the rest of the browsers
  _card._accountCreditCardButtonClick = function (event) {

    if (event.target.classList.contains('focused')) {
      _card._domElements.howToList.style.display = 'none';
      _card._domElements.howToButton.classList.toggle('focused');
    } else {
      _card._domElements.howToList.style.display = 'block';
      _card._domElements.howToButton.classList.toggle('focused');
    }
  };

  // Execute module constructor
  _card._init();
  // Unit testing access
  return _card;
});
